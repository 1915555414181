import { Translations } from '@kvika/survey';
import { Lang } from './languages';

const is = {
  dropdown: {
    chooseAnAnswer: 'Veldu Svar',
    dataInconsistency:
      'Vinsamlegast athugaðu að í Þjóðskrá kemur fram að búseta þín er ekki á Íslandi. Staðfestir þú að hún sé nú á Íslandi?',
  },
  personWidget: {
    fullName: 'Fullt nafn',
    ssn: 'Kennitala',
    jobTitle: 'Starfsheiti',
    mobilePhoneNumber: 'Farsímanúmer',
    shares: 'Eignahlutur í %',
    addPerson: 'Bæta við',
    removePerson: 'Fjarlæga',
    removePersonWarningText: 'Ertu viss um að þú viljir fjarlægja aðila?',
    invalidPhone: 'Símanúmer er ekki á réttu formi',
    invalidSSN: 'Kennitala er ekki á réttu formi.',
    invalidShares: 'Vinsamlegast veldu eignarhlut á bilinu 25% til 100%',
    invalidFullName: 'Vinsamlegast fylltu út nafn',
    invalidSharesSum: 'Summan af eignarhlutum má ekki vera meiri en 100%',
  },
  userRole: {
    BOARD_MEMBER: 'Stjórnarmaður',
    CHAIRMAN_OF_THE_BOARD: 'Stjórnarformaður',
    COMANAGER: 'Meðstjórnandi',
    CEO: 'Framkvæmdarstjóri',
    POWER_OF_ATTORNEY: 'Prókúruhafi',
    OTHER: 'Annað',
  },
} as Translations;

const en = {
  dropdown: {
    chooseAnAnswer: 'Choose an answer',
    dataInconsistency:
      'Please note that the National Registry indicates that your residence is not in Iceland. Do you confirm that it is now in Iceland?',
  },
  personWidget: {
    fullName: 'Full name',
    ssn: 'SSN',
    jobTitle: 'Position',
    mobilePhoneNumber: 'Mobile phone number',
    shares: 'Shares in %',
    addPerson: 'Add',
    removePerson: 'Remove',
    removePersonWarningText: `Are you sure you'd like to remove this person?`,
  },
  userRole: {
    BOARD_MEMBER: 'Board member',
    CHAIRMAN_OF_THE_BOARD: 'Chairman of the board',
    COMANAGER: 'Co manager',
    CEO: 'CEO',
    POWER_OF_ATTORNEY: 'Power of attorney',
    OTHER: 'Other',
  },
} as Translations;

const pl = {};

export default { [Lang.IS]: is, [Lang.EN]: en, [Lang.PL]: pl };
