import ApiClient, { ApiError, StringMap } from '@kvika/api-client';
import { AxiosResponseHeaders } from 'axios';
import { QuestionError } from '@kvika/survey';
import { AnswerWriteSchema, QuestionGroupSchema, QuestionSchema, SurveySchema } from '@kvika/api-types';
import { getTier } from './config';
import { Lang } from './languages';
import { getToken, setToken } from './authenticationStorage';
import translations from './translations';
import { QuestionMap } from '../types/Types';

export const getStringTranslations = (lang: Lang) => {
  return translations[lang];
};

export const getChoiceIdMap = () => {
  return getTier() === 'production'
    ? {
        choiceIdLivingAbroad: 154,
        choiceIdNotALargeRealOwner: 239,
      }
    : {
        choiceIdLivingAbroad: 409,
        choiceIdNotALargeRealOwner: 364,
      };
};

export const getKvikaApiClient = (
  onResponseHeaders?: (headers: AxiosResponseHeaders) => void,
  loginRequestToken?: string
): ApiClient => {
  return new ApiClient({
    apiUrl: process.env.NEXT_PUBLIC_BASE_URL ?? '',
    appToken: process.env.NEXT_PUBLIC_AUTH_TOKEN ?? '',
    authToken: getToken() ?? '',
    storeAuthToken: setToken,
    onResponseHeaders,
    loginRequestToken,
  });
};

export const getApiErrors = (
  error: ApiError,
  answers: AnswerWriteSchema[],
  lang: Lang,
  strings: StringMap
): QuestionError[] => {
  const errors: QuestionError[] = [];
  if (error?.response?.data?.detail) {
    const {
      response: {
        data: { detail },
      },
    } = error;
    try {
      if (detail && Array.isArray(detail) && detail.length > 0) {
        detail.forEach(({ loc, msg }) => {
          // first 2 values we dont use so we use , to skip them in deconstruction
          const [, , answerIndex, answerProperty, personIndex, , , questionProperty] = loc;
          if (
            answerIndex !== undefined &&
            answerProperty !== undefined &&
            personIndex !== undefined &&
            questionProperty !== undefined &&
            typeof questionProperty === 'string'
          ) {
            if (errors.length === 0) {
              errors.push({
                questionId: answers[answerIndex as number].questionId,
                questionProperty: questionProperty as string,
                errorMessage:
                  strings[lang][`ERROR_SURVEY_SUBMISSION_INVALID_${questionProperty.toUpperCase() as string}`] || msg,
                personIndex: personIndex as number,
              });
            }
          }
        });
      }
    } catch (e) {
      return [];
    }
  }
  return errors;
};

/**
 * Returns array of answers of a targeted question from the answers array, or an empty array otherwise.
 * @param answers Array of AnswerModels containing all answered questions
 * @param questionId Target question id
 */
export const getChoiceIds = (answers: AnswerWriteSchema[], questionId: number): number[] => {
  // Remove all other questions and map through so it will be array of ids
  return answers
    .filter((a) => a.questionId === questionId)
    .reduce<number[]>((acc, curr) => {
      // Only include if choiceId is not undefined
      if (curr.choiceId) acc.push(curr.choiceId);
      return acc;
    }, []);
};

/**
 * Returns answered TextValue of a targeted question from the answers array, and an empty string otherwise.
 * @param answers Array of AnswerModels containing all answered questions
 * @param questionId Target question id
 */
export const getTextValue = (answers: AnswerWriteSchema[], questionId: number): string => {
  const targetQuestion = answers.find((a) => a.questionId === questionId);
  return targetQuestion && targetQuestion.textValue ? targetQuestion.textValue : '';
};

/**
 * This goes through arrayA and check if any of it's items includes any of arrayB items
 * @param arrayA Array of numbers
 * @param arrayB Array of numbers
 */
const includesAny = (arrayA: number[], arrayB: number[]): boolean => {
  return arrayA.some((item) => arrayB.includes(item));
};

/**
 * Returns a boolean of if the given question should be visible at the state of given answers.
 * @param answers Array of AnswerModels containing all answered questions
 * @param question Target question to check if it should be visible in the test
 */
export const isQuestionVisible = (answers: AnswerWriteSchema[], question: QuestionSchema): boolean => {
  // If the question does not have a questionId of a depended question, then return true
  if (!question.dependsOnQuestionId) return true;

  // Get the answer/choiceId of a dependant question
  const dependentQuestionChoiceIds = getChoiceIds(answers, question.dependsOnQuestionId);

  // Check if any of the dependencyChoiceIds match any of the dependentQuestionChoiceIds
  const hasDependantQuestionTheRightAnswer = question.dependencyChoiceIds
    ? includesAny(question.dependencyChoiceIds, dependentQuestionChoiceIds)
    : false;

  // Does the dependant question has the right answer
  return hasDependantQuestionTheRightAnswer;
};

const getAllVisibleQuestions = (survey: SurveySchema, answers: AnswerWriteSchema[]): QuestionSchema[] => {
  const allVisibleQuestions: QuestionSchema[] = [];
  survey.questionGroups?.forEach((questionGroup) => {
    questionGroup.questions?.forEach((question) => {
      if (isQuestionVisible(answers, question)) {
        if (question.subQuestions && question.subQuestions.length > 0) {
          question.subQuestions.forEach((subQuestion) => {
            if (isQuestionVisible(answers, subQuestion) && subQuestion.isRequired) {
              allVisibleQuestions.push(subQuestion);
            }
          });
        } else if (question.isRequired) {
          allVisibleQuestions.push(question);
        }
      }
    });
  });

  return allVisibleQuestions;
};

export const getRequiredQuestionsAsMap = (questionGroups: QuestionGroupSchema[] | undefined) => {
  const questionMap: QuestionMap = {};
  if (questionGroups) {
    questionGroups.forEach((questionGroup) => {
      if (questionGroup.questions) {
        questionGroup.questions.forEach((question) => {
          if (question.subQuestions && question.subQuestions.length > 0) {
            question.subQuestions.forEach((subQuestion) => {
              if (subQuestion && subQuestion.isRequired) questionMap[subQuestion.id] = subQuestion;
            });
          } else if (question.isRequired) {
            questionMap[question.id] = question;
          }
        });
      }
    });
  }
  return questionMap;
};

export const getProgressPercentage = (
  test: SurveySchema,
  answers: AnswerWriteSchema[],
  hasAcceptedTerms: boolean,
  isMifidSurvey: boolean,
  requiredQuestionsMap?: QuestionMap
): number => {
  // Collect all visible questions
  const allVisibleQuestions = getAllVisibleQuestions(test, answers);

  // As a failsafe, it should return 0 if there are no questions in the survey
  if (allVisibleQuestions.length === 0) return 0;

  // Collect all questionId without duplication
  // This is needed because each checkbox answer will have its own answers item from the same question
  const uniqueIds = new Set();
  answers.forEach((answer) => {
    if (requiredQuestionsMap) {
      if (requiredQuestionsMap[answer.questionId]) {
        uniqueIds.add(answer.questionId);
      }
    } else {
      uniqueIds.add(answer.questionId);
    }
  });

  // Add terms answer to answer list if this is not a mifid survey
  !isMifidSurvey && hasAcceptedTerms && uniqueIds.add(-1);

  // Return the procentage, like 0.0 to 100.0 so 75% will return 75;
  // Add + 1 to visible questions to account for terms answer (if this is not a mifid survey)
  const numOfVisibleQuestions = isMifidSurvey ? allVisibleQuestions.length : allVisibleQuestions.length + 1;
  return (uniqueIds.size / numOfVisibleQuestions) * 100;
};
