import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Banner } from '@kvika/header';
import { prettifySSN } from '@kvika/string-utils';
import { getTranslation } from '../../utils/languages';
import { selectAuthState } from '../../store/auth';
import { selectLang } from '../../store/lang';
import { selectCompanyInfo } from '../../store/company';

import { StyledButton, StyledButtonText, StyledButtonWrapper, StyledContainer } from './QuestionPageBannerStyles';

type QuestionPageBannerProps = {
  isCompany: boolean;
  hasCompanies: boolean;
  isChild: boolean;
  hasChildren: boolean;
};

const QuestionPageBanner = ({ isCompany, hasCompanies, isChild, hasChildren }: QuestionPageBannerProps) => {
  const router = useRouter();
  const lang = useSelector(selectLang);
  const { companyName, companySsn } = useSelector(selectCompanyInfo);
  const { user } = useSelector(selectAuthState);

  const answeringQuestiongsOnBehalfOf_string = getTranslation(
    lang,
    'Þú ert að svara spurningum fyrir ',
    'You are answering questions on behalf of ',
    ''
  );

  const loggedInAs_string = getTranslation(lang, 'Þú ert innskráð/ur sem ', 'You are logged in as ', '');

  const switchLegalEntity_string = getTranslation(lang, 'Skipta um lögaðila', 'Switch legal entity ', '');

  const switchChild_string = getTranslation(lang, 'Breyta um aðila', 'Switch entity', '');

  const renderIndividualOrCompany = (
    answeringFor: string,
    name: string,
    ssn: string,
    hasCompaniesOrChildren: boolean,
    path: string,
    switchLegalEntityOrChild: string
  ) => {
    return (
      <Banner>
        <StyledContainer>
          {answeringFor} {name} ({prettifySSN(ssn || '')})
          {hasCompaniesOrChildren && (
            <StyledButtonWrapper>
              <StyledButton
                onClick={() => {
                  router.push(path);
                }}
              >
                <StyledButtonText> {switchLegalEntityOrChild}</StyledButtonText>
              </StyledButton>
            </StyledButtonWrapper>
          )}
        </StyledContainer>
      </Banner>
    );
  };

  const renderContent = () => {
    if (isCompany && companyName && companySsn) {
      return renderIndividualOrCompany(
        answeringQuestiongsOnBehalfOf_string,
        companyName,
        companySsn,
        hasCompanies,
        '/companies',
        switchLegalEntity_string
      );
    }
    if (isChild) {
      return renderIndividualOrCompany(
        answeringQuestiongsOnBehalfOf_string,
        user.name,
        user.ssn,
        true,
        '/children',
        switchChild_string
      );
    }
    return renderIndividualOrCompany(
      loggedInAs_string,
      user.name,
      user.ssn,
      hasChildren,
      '/children',
      switchChild_string
    );
  };
  return <div>{renderContent()}</div>;
};

export default QuestionPageBanner;
