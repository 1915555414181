import * as React from 'react';
import Image from 'next/legacy/image';
import { MediaQuery } from '@kvika/theme';
import styled from 'styled-components';

type Props = {
  url: string;
  alt?: string;
  quality?: number;
};

// This is reccomended by Next.js https://nextjs.org/docs/api-reference/next/image#fill when using the fill property
const StyledImageWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const LayoutImage = ({ url, alt = 'Default alt text', quality = 75 }: Props) => {
  if (!url) {
    return null;
  }

  return (
    <StyledImageWrapper>
      <Image
        src={url}
        quality={quality}
        alt={alt}
        priority
        layout="fill"
        sizes={`(min-width: ${MediaQuery.Laptop}) 50vw,
display: none;`}
      />
    </StyledImageWrapper>
  );
};

export default LayoutImage;
