import styled from 'styled-components';
import { Grid, Colors } from '@kvika/theme';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const StyledButtonText = styled.p`
  text-decoration: underline;
  font-weight: bold;
  color: ${Colors.gold600};
`;

export const StyledButtonWrapper = styled.div`
  margin-left: ${Grid['8px']};
`;

export const StyledButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: ${Grid['16px']};
`;
