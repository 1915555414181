import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import Layout, { ChildrenWrapper, JustifyContentType } from '@kvika/layout';
import Header from '@kvika/header';
import ProgressBar from '@kvika/progress';
import Button from '@kvika/button';
import { selectLang, updateLang } from '../store/lang';
import { getTranslation, Lang } from '../utils/languages';
import LanguagePicker from './Header/LanguagePicker/LanguagePicker';
import { getKvikaApiClient } from '../utils/surveyUtils';
import { logout, selectLoggedIn } from '../store/auth';
import { clearSession } from '../utils/authenticationStorage';
import LogoutButton from './Header/LogoutButton';
import { SegmentTrackingId, trackEvent } from '../utils/analytics';
import QuestionPageBanner from './Survey/QuestionPageBanner';
import { isStaging } from '../utils/config';
import LayoutImage from './LayoutImage';

type Props = {
  backgroundImageUrl?: string;
  showProgressBar?: boolean;
  showBanner?: boolean;
  showClearAnswersButton?: boolean;
  entity?: EntityProps;
  progressPercentage?: number;
  children: JSX.Element | JSX.Element[];
  justifyContent?: JustifyContentType;
  alt?: string;
};

export type EntityProps = {
  isCompany?: boolean;
  hasMultipleCompanies?: boolean;
  isChild?: boolean;
  hasChildren?: boolean;
  ssn?: string;
};

const StyledProgressBar = styled(ProgressBar)`
  position: relative;
  z-index: 100;
`;

const StyledProgressbarContainer = styled.div`
  position: sticky;
  top: 112px;
  z-index: 1;
`;

const LayoutWrapper: FunctionComponent<Props> = ({
  backgroundImageUrl = '/fjallgardur.jpg',
  showProgressBar = false,
  showBanner = false,
  showClearAnswersButton = false,
  entity,
  progressPercentage = 0,
  children,
  justifyContent = JustifyContentType.FlexStart,
  alt = 'Fjallgarður og landslag',
}) => {
  const [isClearAnswersLoading, setIsClearAnswersLoading] = useState(false);
  const lang = useSelector(selectLang);
  const apiClient = getKvikaApiClient();
  const dispatch = useDispatch();
  const router = useRouter();
  const isLoggedIn = useSelector(selectLoggedIn);

  const logoutBtnTitle_string = getTranslation(lang, 'Útskrá', 'Log out', 'Wyloguj');

  const onLogout = () => {
    dispatch(logout());
    clearSession();
    router.push('/');
  };

  const handleUpdateLang = (lang: Lang) => {
    dispatch(updateLang(lang));
    trackEvent({
      event: SegmentTrackingId.LanguageChangedTo.replace('XX', lang) as SegmentTrackingId,
      properties: { language: lang },
    });
  };

  const onClearAnswers = async () => {
    setIsClearAnswersLoading(true);
    const isCompany = entity?.isCompany;
    const ssn = entity?.ssn;
    if (isCompany) {
      if (ssn) {
        await apiClient.clearCompanyAnswersOnStaging(ssn);
      }
    } else {
      await apiClient.clearAnswersOnStaging();
    }
    setIsClearAnswersLoading(false);
    onLogout();
  };

  return (
    <Layout image={<LayoutImage url={backgroundImageUrl} quality={75} alt={alt} />}>
      <Header>
        {isStaging() && showClearAnswersButton && (
          <div>
            <Button loading={isClearAnswersLoading} onClick={onClearAnswers}>
              Hreinsa svör
            </Button>
          </div>
        )}
        {isStaging() && <LanguagePicker lang={lang} handleUpdateLang={handleUpdateLang} />}
        {isLoggedIn && <LogoutButton text={logoutBtnTitle_string} onClick={onLogout} />}
      </Header>
      {showProgressBar && (
        <StyledProgressbarContainer>
          <StyledProgressBar percentage={progressPercentage} />
        </StyledProgressbarContainer>
      )}
      {showBanner && (
        <QuestionPageBanner
          isCompany={entity?.isCompany ?? false}
          hasCompanies={entity?.hasMultipleCompanies ?? false}
          isChild={entity?.isChild ?? false}
          hasChildren={entity?.hasChildren ?? false}
        />
      )}
      <ChildrenWrapper justifyContent={justifyContent}>{children}</ChildrenWrapper>
    </Layout>
  );
};
export default LayoutWrapper;
