import { Language } from '@kvika/icons';
import * as React from 'react';
import { Lang } from '../../../utils/languages';
import { StyledButton } from '../ButtonStyles';
import { StyledArrow, StyledLangDivider, StyledLangItem, StyledLangPicker } from './LanguagePickerStyles';

type Props = {
  lang: Lang;
  handleUpdateLang(lang: Lang): void;
};

const getLanguageStringFromEnum = (lang: Lang): string => {
  switch (lang) {
    case Lang.EN:
      return 'English';

    case Lang.PL:
      return 'Polskie';

    default:
      return 'Íslenska';
  }
};

const LanguagePicker = ({ lang, handleUpdateLang }: Props): JSX.Element => {
  const [showLanguagePicker, setShowLanguagePicker] = React.useState(false);

  const onClick = (lang: Lang) => {
    handleUpdateLang(lang);
    setShowLanguagePicker(false);
  };
  return (
    <div>
      <StyledButton onClick={() => setShowLanguagePicker(!showLanguagePicker)}>
        {getLanguageStringFromEnum(lang)} <Language />
      </StyledButton>
      {showLanguagePicker && (
        <StyledLangPicker>
          <StyledArrow />
          <StyledLangItem onClick={() => onClick(Lang.IS)}>{getLanguageStringFromEnum(Lang.IS)}</StyledLangItem>
          <StyledLangDivider />
          <StyledLangItem onClick={() => onClick(Lang.EN)}>{getLanguageStringFromEnum(Lang.EN)}</StyledLangItem>
        </StyledLangPicker>
      )}
    </div>
  );
};

export default LanguagePicker;
